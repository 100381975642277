<template>
  <div class="">
    <v-card v-for="(i, iI) in items" :key="'dispatch-item-' + iI" class="px-4 py-2 rounded-lg my-2" outlined @click.prevent="$emit('show', i)">
      <div class="d-flex fill-width align-center justify-space-between">
        <div class="d-flex align-center">
          <v-icon small class="mr-1">
            mdi-cube-send
          </v-icon>
          #{{ i.id }} {{ i.dispatch_vehicle_name ? (i.dispatch_vehicle_name + ' ' + i.dispatch_vehicle_plat_number) : i.dispatch_vehicle_notes }}
        </div>
        <span v-if="parseInt(i.manifest_count)" class="caption font-weight-bold">
          {{ parseInt(i.manifest_count) }} MANIFEST
        </span>
      </div>
      <div class="d-flex align-center justify-space-between">
        <v-chip x-small color="primary">
          {{ i.store_origin_name }}
        </v-chip>
        <div class="px-2" style="max-width: 70%;min-width: 50%;">
          <div v-if="i.position" class="d-flex fill-width justify-space-between">
            <v-icon x-small>
              mdi-transfer-right
            </v-icon>
            <div v-if="ParsePosition(i.position).departure && !ParsePosition(i.position).arrival" class="d-flex align-center text-uppercase">
              {{ ParsePosition(i.position).position }}
              <v-icon x-small class="mx-2">
                mdi-chevron-right
              </v-icon>
              {{ ParsePosition(i.position).destination_city }}
            </div>
            <div v-else class="text-uppercase">
              {{ ParsePosition(i.position).position }}
            </div>
            <v-icon x-small>
              mdi-transfer-right
            </v-icon>
            <!-- -> {{ i.position }} -->
          </div>
          <div v-else class="d-flex fill-width justify-center grey--text lighten-5 caption">
            - STANDBY -
          </div>
        </div>
        <v-chip x-small color="primary">
          {{ i.store_destination_name }}
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: []
  }),
  mounted () {
    this.DISPATCH_LOAD()
  },
  methods: {
    DISPATCH_LOAD () {
      this.$store.dispatch('logistic/DISPATCH_GET', '?main=1')
        .then((res) => {
          if (res && res.status) {
            this.items = res.data.data || []
          }
        })
    },
    ParsePosition (str) {
      const data = (str || '').split('<|>')
      const deparr = data[0].split('|')
      const origin = data[1].split('|')
      const destination = data[2].split('|')
      const position = (!deparr[0] && !deparr[1])
        ? ('PREPARE : ' + origin[0])
        : (deparr[0] && !deparr[1])
          ? origin[0]
          : destination[0]
      const result = {
        departure: deparr[0] || '',
        arrival: deparr[1] || '',
        origin_city: origin[0] || '',
        origin_type: origin[1] || '',
        origin_province: origin[2] || '',
        destination_city: destination[0] || '',
        destination_type: destination[1] || '',
        destination_province: destination[2] || '',
        position
      }
      return result
    }
  }
}
</script>

<style lang="scss">
</style>
